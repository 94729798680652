/* .App {
  text-align: center;
} */
/*
some hacks from Aria to size the image relative to the text on about-me-page. Breaks
when the column vertically stacks, doesn't like shadows either.
.image-container { position: relative; }
.image-container-image { position: absolute; top: 0; left: 0; height: 100%; width: 100%; }
*/
/*.of {position: absolute; top: 0; left: 0; height: 100%; width: 100%; object-fit: contain } */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
